<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumnsDet" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible3" size="30%" title="退费">
      <div v-if="formDrawerVisible3" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="retreatColumns" type="add" @submit="retreatSubmit" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { courseOrderDel, courseOrderList, courseOrderRefund, courseOrderSubmit } from '@/apis/common'
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'
import formSee from '@/components/system/formSee.vue'

export default {
  name: 'lesson',
  components: { formSee, Form, listTable, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      formData: {
        name: '',
        course_name: '',
        start_date: '',
        end_date: '',
        user_id: '',
      },
      list: [],
      toolbar: [
        {type: 'input', label: '学生姓名', model: 'name', value: ''},
        {type: 'input', label: '课程名称', model: 'course_name', value: ''},
        {type: 'date', label: '开始日期', model: 'start_date', value: ''},
        {type: 'date', label: '结束日期', model: 'end_date', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      columns: [
        {label: '学生姓名', prop: 'name', width: 100, type: 'text'},
        {label: '课程名称', prop: 'course_name', width: 100, type: 'text'},
        {label: '课包名称', prop: 'package_name', width: 100, type: 'text'},
        {label: '销售员姓名', prop: 'saler_name', width: 100, type: 'text'},
        {label: '数量', prop: 'num', width: 100, type: 'text'},
        {label: '单价', prop: 'price', width: 100, type: 'text'},
        {label: '总额', prop: 'total_price', width: 100, type: 'text'},
        {label: '有效期', prop: 'validity', width: 80, type: 'tag',  arr: [{key: 0,type: 'success', label: '永久'}, {key: 1,type: 'success', label: '限期'}]},
        {label: '收款方式', prop: 'pay_type', width: 80, type: 'tag',  arr: [
            {key: 1,type: 'success', label: '微信'},
            {key: 2,type: 'success', label: '支付宝'},
            {key: 3,type: 'success', label: '现金'},
            {key: 4,type: 'success', label: '转账'},
            {key: 5,type: 'success', label: '其他'},
            {key: 6,type: 'success', label: '余额'},
          ]},
        {label: '到期时间', prop: 'end_date', width: 150, type: 'text'},
        {label: '备注', prop: 'note', width: 260, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'warning', icon: 'el-icon-edit', label: '退费', onClick: (row) => this.refunds(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      formDrawerVisible: false,
      formDrawerVisible2: false,
      formDrawerVisible3: false,
      addColumns: [
        {label: '学生名', prop: 'user_id', type: 'student',value: '', options: []},
        {label: '课程名', prop: 'course_id', type: 'course',value: ''},
        {label: '课包名', prop: 'course_package_id', type: 'package',value: ''},
        {label: '销售单价', prop: 'price', single: true, type: 'input',value: ''},
        {label: '课程数量', prop: 'num', num: true, type: 'input',value: ''},
        {label: '缴费金额', prop: 'total_price', and: true, disabled: true, type: 'input',value: ''},
        {label: '标准单价', prop: 'standard_price', type: 'input',value: ''},
        {label: '有效期', prop: 'validity', linkage: 'one', main: true, type: 'select',value: '' , options: [
            {value: 0,label: '永久'},
            {value: 1,label: '限期'},
          ]},
        {label: '到期日期', prop: 'end_date', linkage: 'one', linkageValue: '1',type: 'date',value: ''},
        {label: '收款方式', prop: 'pay_type', value: '', type: 'select', options: [
            {label: '微信', value: 1},
            {label: '支付宝', value: 2},
            {label: '现金', value: 3},
            {label: '转账', value: 4},
            {label: '其他', value: 5},
            {label: '余额', value: 6},
          ]},
        {label: '备注', prop: 'note', type: 'textarea',value: ''},
      ],
      typeForm: 'add',
      addColumnsDet: [
        {label: '课程名称', prop: 'course_name', type: 'input', value: ''},
        {label: '学生姓名', prop: 'name', type: 'input', value: ''},
        {label: '课程名称', prop: 'course_name', type: 'input', value: ''},
        {label: '课包名称', prop: 'package_name', type: 'input', value: ''},
        {label: '单价', prop: 'price', type: 'input', value: ''},
        {label: '标准单价', prop: 'standard_price', type: 'input', value: ''},
        {label: '数量', prop: 'num', type: 'input', value: ''},
        {label: '总额', prop: 'total_price', type: 'input', value: ''},
        {label: '有效期', prop: 'validity', type: 'select',value: '' , options: [
            {value: 0,label: '永久'},
            {value: 1,label: '限期'},
          ]},
        {label: '缴费类型', prop: 'type', type: 'select',value: '' , options: [
            {value: 1,label: '首次缴费'},
            {value: 2,label: '续费'},
            {value: 3,label: '退费'},
          ]},
        {label: '收款方式', prop: 'pay_type', type: 'select',value: '' , options: [
            {label: '微信', value: 1},
            {label: '支付宝', value: 2},
            {label: '现金', value: 3},
            {label: '转账', value: 4},
            {label: '其他', value: 5},
            {label: '余额', value: 6},
          ]},
        {label: '备注', prop: 'note', type: 'input', value: ''},
      ],
      retreatColumns: [
        {label: '退课数量', prop: 'num', type: 'input',value: ''},
        {label: '退费金额', prop: 'total_price', type: 'input',value: ''},
        {label: '标准单价', prop: 'standard_price', type: 'input',value: ''},
        {label: '收款方式', prop: 'pay_type', value: '', type: 'select', options: [
            {label: '微信', value: 1},
            {label: '支付宝', value: 2},
            {label: '现金', value: 3},
            {label: '转账', value: 4},
            {label: '其他', value: 5},
            {label: '余额', value: 6},
          ]},
        {label: '备注', prop: 'note', type: 'textarea',value: ''},
      ]
    }
  },
  mounted() {
    this.getCourseOrderList()
  },
  methods: {
    retreatSubmit(e) {
      courseOrderRefund({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        order_id: this.addFormData.id,
        ...e
      }).then(r => {
        this.formDrawerVisible3 = false
        this.$message.success('操作成功')
        this.getCourseOrderList()
      })
    },
    refunds(row) {
      this.addFormData = row
      this.formDrawerVisible3 = true
    },
    // 查看
    seeDetails(row) {
      this.addColumnsDet.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible2 = true
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...e
      }
      courseOrderSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getCourseOrderList()
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseOrderDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          order_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getCourseOrderList()
        })
      }).catch(() => {})
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getPackageList()
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getCourseOrderList()
    },
    getCourseOrderList() {
      this.loading = true
      courseOrderList({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
